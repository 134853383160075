var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { color: "indigo darken-1", icon: "" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showForm = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Novo Cadastro ")])]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("EmcTableItems", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.integration_item.name) + " "),
                _c("small", [
                  _c("br"),
                  _vm._v(_vm._s(item.integration_item.description))
                ])
              ]
            }
          },
          {
            key: "item.direction_integration",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-icon",
                  {
                    attrs: {
                      small: "",
                      color:
                        _vm.direction[
                          item.integration_item.direction_integration
                        ].color
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.direction[
                          item.integration_item.direction_integration
                        ].icon
                      )
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("SysIntegrationInstitutionItemRegister", {
        attrs: {
          icon: _vm.$route.meta.icon,
          model: _vm.model,
          show: _vm.showForm
        },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.text, show: _vm.show, model: _vm.model },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          },
          "update:model": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }